<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li class="active"><router-link to="/mt4">MT4</router-link></li>
            <li><router-link to="/mt5">MT5</router-link></li>
            <li><router-link to="/act-trader">ActTrader</router-link></li>
            <li><router-link to="/zulu">ZuluTrade</router-link></li>
          </ul>
        </div>
      </div>

      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>MetaTrader 4</h2>
        <div class="img-holder">
          <img src="assets/images/mt4-img.webp" alt="AAAFx" title="AAAFx" width="726" height="394" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>
            The MetaTrader 4 platform created back in 2002 by MetaQuotes. MT4
            platform is considered the gold-standard software among the wide
            array of trading platforms; used for trading forex, CFDs, stocks and
            various popular crypto currencies.
          </h6>
          <br />
          <p>
            AAAFx MT4 platform provides a user-friendly interface, enabling
            users to understand the platform's features with absolute ease. It
            assimilates a user's requirements and offers the best possible
            solution by providing the ability to apply various analysis tools,
            run trading robots (expert advisors), and copy trades from others.
          </p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="content-sec">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/mt4-img1.webp" alt="AAAFx" title="AAAFx" width="700" height="412" class="fluid-img" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/mt4-ico1.webp" alt="AAAFx" title="AAAFx" width="371" height="95" class="fluid-img" />
            <br /><br />
            <p>
              Enhance your trading experience on a bigger screen with greater
              functionalities and greater focus by downloading the MT4 platform
              on your desktop or simply access our web-based platform.
            </p>
          </div>
          <div class="btn-holder wow fadeIn">
            <a
              :href="static_vars.platformLinks.mt4.desktop"
              class="btn btn-grey"
              title="MT4 Desktop"
            >
              <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt4.desktop == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT4 Desktop"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt4.mac"
              class="btn btn-grey"
              title="MT4 Mac"
            >
              <img src="assets/images/desk-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt4.mac == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT4 Mac"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt4.web"
              class="btn btn-grey"
              title="MT4 Web"
              target="_blank"
            >
              <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />             
              {{ (static_vars.platformLinks.mt4.web == "javascript:void(0);" || static_vars.platformLinks.mt4.web == "")   ? "Coming Soon"   : "Web Based MT4" }}
            </a>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="desktop ? 'active' : ''">
              Steps to download MT4 Desktop
            </h3>
            <div class="content" v-show="desktop">
              <p>
                1. Download the platform from above link. <br />
                2. Run the .exe file <br />
                3. Go to File> Open an account” <br />
                4. Click on the Add New Broker and type AAAFX. Select
                AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live
                account. <br />
                5. Click on the Scan button. The server is now added. <br />
                6. Click on next> select “Existing trade account” check box and
                enter your login ID and Password. Click on Finish. <br />
              </p>
            </div>
            <h3
              v-on:click="uninstalldesktop ^= true"
              :class="uninstalldesktop ? 'active' : ''"
            >
              Steps to uninstall MT4 Desktop
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                1. Click on Start button in Windows <br />
                2. The MT4 application will be included in list of programs.
                <br />
                3. Right click on the application name and select `Uninstall`.
                <br />
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="web ? 'active' : ''">
              Steps to log into Web-based MT4
            </h3>
            <div class="content" v-show="web">
              <p>
                1. On the Web-Trader, go to “File” option on the menu bar and
                select “Login to Trade account” <br />
                2. Enter trading account ID and password. <br />
                3. Select server as “AAAFX-FX-REAL” for live account and
                “AAAFX-FX-DEMO” for demo account <br />
                4. Then select the platform MT4 and click on “OK”. <br />
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/mt4-ico2.webp" alt="AAAFx" title="AAAFx" width="371" height="95" class="fluid-img" />
              <br /><br />
              <p>
                Take your trading euphoria anywhere you go with all the trading
                tools and indicators available for AAAFx mobile trading
                application.
              </p>
              <div class="btn-holder wow fadeIn">
                <a
                  :href="static_vars.platformLinks.mt4.android"
                  class="btn btn-grey"
                  title="MT4 Android"
                >
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt4.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT4 Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.mt4.ios"
                  class="btn btn-grey"
                  title="MT4 iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt4.ios == "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT4 iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/mt4-img2.webp" alt="AAAFx" title="AAAFx" width="650" height="392" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="android ? 'active' : ''">
              Steps to download MT4 Android
            </h3>
            <div class="content" v-show="android">
              <p>
                1. Download the application from above link. <br />
                2. Click on three horizontal lines in Menu and go to “manage
                accounts”/ “Get started”. <br />
                3. Click on the “+” sign on the top right corner. <br />
                4. Select “Login to an existing account” <br />
                5. Under `Find your broker` select AAAFX-FX-DEMO for demo
                account and AAAFX-FX-REAL for live account. Now enter your login
                ID and password and click on login.
              </p>
            </div>
            <h3
              v-on:click="unistallandroid ^= true"
              :class="unistallandroid ? 'active' : ''"
            >
              Steps to uninstall MT4 Android
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                1. On the Menu, go to Settings > Applications > Manage
                applications <br />
                2. Find MT4 listed in there and tap on its name. <br />
                3. Tap 'Uninstall'.
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              Steps to download MT4 iOS
            </h3>
            <div class="content" v-show="ios">
              <p>
                1. Download the application from above link. <br />
                2. Click on "Settings" and go to "New Account" Option, <br />
                3. Then click "Login to an existing Account" <br />
                4. Enter “AAAFX” under find broker. <br />
                5. Select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for
                live account. <br />
                6. Enter your Login ID and Password and click on "Sign in"
              </p>
            </div>
            <h3
              v-on:click="unistallios ^= true"
              :class="[{ active: unistallios }]"
            >
              Steps to uninstall MT4 iOS
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                1. Long press on the MT4 application icon until all the icons on
                your screen begin to shake. <br />
                2. Click the 'x' in the upper left-hand corner of the icon for
                the MT4 application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            Take your MT4 platform trading experience with AAAFx to the next
            level with the following features:
          </h3>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/mt4-img3.webp" alt="AAAFx" title="AAAFx" width="1157" height="358" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>Why AAAFx's MT4?</h3>
            </div>
            <ul class="listing-check">
              <li>Associated with the largest trading community of MQL</li>
              <li>20+ graphical objects for in-depth analysis</li>
              <li>Real-time market price</li>
              <li>Simple to understand interface</li>
              <li>
                Compatible on windows, iphone, android or simply trade directly
                from the web
              </li>
              <li>
                Ready-to-use Scripts; allowing an automated trading experience
              </li>
              <li>
                1000+ free and downloadable indicators/EAs offered by the MQL4
              </li>
              <li>
                Fastest execution speed with multiple servers across the world
              </li>
              <li>
                70+ forex currency pairs, global indices, and commodities
                available to trade
              </li>
              <li>Market execution and 4 types of pending orders available</li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/mt4-img4.webp" alt="AAAFx" title="AAAFx" width="580" height="407" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Support: false,
      Finance: false,
      Administrator: false,
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
